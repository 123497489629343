import React, { useState, useEffect } from "react";
import { ListComponent } from "./ListHistory.styles";
import { format } from "date-fns";

function ListHistory({ item, stt, page }) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return (
    <ListComponent>
      <ListComponent.TextNumber>{(5 * page + stt + 1) - 5}</ListComponent.TextNumber>
      <ListComponent.TextDate>
        {format(new Date(item?.createdAt), "dd/MM/yyyy")}
      </ListComponent.TextDate>

      <ListComponent.TextTrade>
        {item?.position == "BUY" && item?.status != "CANCEL"
          ? "Mua"
          : item?.status == "CANCEL"
            ? "Hủy"
            : "Bán"}
      </ListComponent.TextTrade>

      {item?.status == "LOSE" ? (
        <ListComponent.TextProfitSell>-{formatter.format(item?.profit)}</ListComponent.TextProfitSell>
      ) : item?.status == "CANCEL" ? (
        <ListComponent.TextProfitBuy className="text-[#B9D2FF]">$0</ListComponent.TextProfitBuy>
      ) : (
        <ListComponent.TextProfitBuy>+{formatter.format(item?.profit)}</ListComponent.TextProfitBuy>
      )}

      <ListComponent.Invest>{formatter.format(item?.amount)}</ListComponent.Invest>
    </ListComponent>
  );
}
export default ListHistory;
